export const message = {
	language: "CN",
	mine:{
		mine1:'我的订单',
		mine2:'账户设置',
		mine3:'我的地址',
		mine4:'客户支持',
		mine5:'退出登录',
		order1:'全部订单',
		order2:'待支付',
		order3:'待发货',
		order4:'已完成',
		order11:'待收货',
		order12:'已关闭',
		finished:'没有更多了',
		loading:'加载中...',
		nodata:'暂无相关订单',
		order5:'订单编号',
		order6:'款式',
		order7:'数量',
		order8:'共',
		order9:'件',
		order10:'总计',
		pay1:'5分钟内订单自动关闭',
		pay2:'修改支付方式',
		pay3:'立即支付',
		pay4:'确定支付',
		pay5:'请选择支付方式',
		pay6:'售后支持',
		pay7:'查看物流',
		log1:'您的物流信息可前往物流服务商官网输入运单号进行查询',
		log2:'物流服务商',
		log3:'物流单号',
		log4:'确定',
		after1:'在线时间：10:30 - 18:00（法定节假日除外）',
		copy:'复制成功',
		detail1:'请继续支付，5分钟内未付款，订单将自动取消',
		detail2:'订单详情',
		detail3:'商品总额',
		detail4:'快递费',
		detail5:'支付平台手续费',
		detail6:'订单号',
		detail7:'信息摘要',
		detail8:'状态',
		detail9:'支付方式',
		detail10:'寄送至',
		detail11:'订单日期',
		confirm1:'确认订单',
		confirm2:'配送服务',
		confirm4:'订单详情',
		confirm5:'提交订单',
		confirm6:'新增新的地址',
		tip1:'提示',
		tip2:'我已知晓并同意下单',
		address1:'地址列表',
		address2:'新增地址',
		address3:'新增新的地址',
		address4:'编辑',
		address5:'删除',
		address6:'收件人姓名',
		address7:'省市区',
		address8:'邮政编码',
		address9:'联系电话',
		address10:'详细地址',
		address11:'是否为默认',
		address12:'保存',
		support1:'售后支持',
		support2:'常见问题',
		support3:'发送邮件至',
		support4:'我们的客服从10:30-18:00（法定节假日除外）为您提供售后相关的咨询服务。',
		tip3:'海外商品一经售出，概不退换。',
		tip4:'购买前请仔细确认商品信息，',
		tip5:'确认无误后在进行购买。',
		tip6:'感谢您的理解与支持',
		order13:'配送地址',
		order14:'请填写您的地址以便配送服务',
		order15:'选择您的支付方式',
		order16:'确认您的订单信息',
		order17:'不支持该地区售卖',
		paying1:'支付中...',
		paying2:'正在支付中...',
		paying3:'请不要关闭本页面，稍等片刻',
		mine:'我的',
		noorder:'暂无订单',
		password:'旧密码',
		password1:'新密码',
		password2:'请输入旧密码',
		password3:'请输入新密码',
		password4:'请再次输入新密码',
		password5:'旧密码错误',
		pay8:'剩余支付时间',
		logout1:'是否确认退出登录',
		cancel:'取消',
		showtips:'您可以使用手机号码或邮箱登录JJ20×NEIGHBORHOOD网站',
		accountDetail:'账号详情'
	},
	login:{
		email:'邮箱',
		email1:'请再次输入邮箱',
		email2:"再次输入邮箱",
		email3:"请输入邮箱",
		email4:"请输入有效邮箱",
		email5:"两次邮箱输入不一致",
		email6:"邮箱验证码",
		email7:"输入验证码",
		email8:"获取验证码",
		email9:"确认邮箱",
		submitTips:'如果您没有在收件箱中找到验证码邮件，请检查您的垃圾箱或广告邮件文件夹。由于邮件过滤设置，有时我们的邮件可能会被误分类。如果仍然找不到，请尝试重新发送验证码或联系客户支持获取帮助。',
		password:'密码',
		password1:'请输入密码',
		password2:'请再次输入密码',
		password3:'至少包含',
		password4:'英文字母/数字/符号',
		password5:'中的两项，',
		password6:'并且不低于8位',
		password7:'确认密码',
		password8:'密码安全',
		password9:'密码安全性低',
		password10:'两次输入的密码不一致',
		account:'账号',
		account1:'请输入账号',
		account2:'邮箱/手机号',
		account3:'确认账号信息',
		account4:'请输入有效账号',
		account5:'账号或密码错误',
		account6:'请输入有效号码',
		agree:'我已阅读并同意',
		agree1:'用户协议',
		agree2:'与',
		agree3:'隐私条款',
		agree4:'请勾选用户协议与隐私条款',
		login:'登录',
		register:'没有账号？',
		register1:'注册',
		register2:'去注册',
		forget:'忘记密码？',
		nextstep:'下一步',
		upstep:'上一步',
		phone:'手机号',
		phone1:'请输入手机号',
		phone2:'手机验证码',
		phone3:'确认手机号',
		code:'验证码无效',
		code1:'验证码',
		code2:'请输入验证码',
		code3:'验证码失效，请重新发送验证码',
		toast:'注册成功',
		toast1:'修改成功',
		toast2:'发送成功',
		findphone:'手机找回',
		findemail:'邮箱找回',
		changepass:'修改密码',
		findpass:'忘记密码',
		surechange:'确定修改',
		areaCode1:'选择区号',
	},
	shopMall:{
		shopHome: '首页',
		startSelling: '开始预售',
		selling: '售卖中',
		willStart: '即将开售',
		sellOut: '已售罄',
		privacy: '隐私政策',
		service: '服务条款',
		cookieNotice: '为了优化您的体验，我们使用Cookie来收集信息并个性化内容。请点击“接受”以继续使用我们的网站。',
		cookieReject: '拒绝',
		cookieAccept: '接受',
		introTitle: 'JJ20即將來到你的NEIGHBORHOOD！',
		introContent01: "为纪念JJ林俊杰出道20周年，JJ林俊杰与自己欣赏和支持的日本街头文化的著名品牌 NEIGHBORHOOD首度携手合作，推出「JJ20  x NEIGHBORHOOD」联名系列！这次合作也因为恰逢NEIGHBORHOOD 成立30周年里程碑，而别具意义。",
		introContent02: 'JJ20巡演10月即将登陆东京，正是将里原宿文化搬上舞台的最佳时刻。',
		introContent03: '隆重介绍 JJ20 x NEIGHBORHOOD：限量版系列，旨在致敬音乐、街头文化以及两个标志性周年纪念日。',
		number: '数量',
		addCart: '加入购物车',
		userNotPay: '有用户拍下未付款',
		againBuy: '稍后再来',
		noneGoods: '购物车暂时没有商品',
		shoppingLook: '去逛逛看吧',
		goOnShoping: '继续购物',
		showAllGoods: '展开全部失效商品',
		settleAccounts: '去结账',
		notice: '提示',
		deleteGoodsNotice: '是否移除该商品',
		cancel: '取消',
		remove: '移除',
		removeSuccess: '移除成功',
		shopDetail: '详情',
		shopCart: '购物车',
		goodsSellOut: '商品已售罄',
		addSuccess: '加购成功',
		buyNotice: '购买须知',
		selectText: '请选择',
		selectSkuTips: '请选择要购买的',
		willSell: '即将开售',
		noSkuTips: '库存不足请稍后尝试刷新',
		settleAccountsTips: '请选择要结账的商品',
		exceedLimitNum: '超出商品限购数量',
		miniNum: '商品最少购买一件',
	},
	newadd:{
		address1:'默认地址',
		pc1:'请输入收件人姓名',
		pc2:'请选择省市区',
		pc3:'请输入邮政编码',
		pc4:'请输入联系电话',
		pc5:'请输入详细地址',
		confirm:'确认',
		delete:'是否删除该地址',
		ordernew:'待支付',
		handlefail:'网络信号差',
		handleHome:'返回首页',
	},
	privacy: {
		line1: '本协议更新时间：2024年9月16日',
		line2: '本协议生效时间：2024年9月16日',
		line3: '《JJ20XNEIGHBORHOOD商城》服务（以下简称本服务）系由上海欧露塔科技有限公司 （以下简称本公司）向您提供的用户服务。本公司非常重视您的隐私及个人信息保护，并基于法律法规及本服务的服务条款制定本隐私政策，以提供您更完善的服务内容。',
		line4: '请您务必经过充裕的时间仔细阅读并充分了解本隐私政策的所有内容，一旦您以任何形式或方式开始使用本服务，即表示您已同意接受本隐私政策的所有内容。',
		line5: '您的年龄如果未满18岁，请您在使用本服务之前，务必在您的法定代理人、监护人的陪同下经过充裕的时间仔细阅读并充分了解本隐私政策的所有内容，一旦您以任何形式或方式开始使用本服务，即表示您已获得法定代理人、监护人同意接受本隐私政策的所有内容。',
		line6: '1、本公司得变更本隐私政策的任一内容，并以公告、电子邮件通知或其他合理的方式通知您，您如果不同意本隐私政策，请您立即停止使用本服务，一旦您在前述变更后继续使用本服务，即视为您已经同意接受本隐私政策的变更。',
		line7: '2、本公司于运营地域、期间内，为了营销、推广、执行本服务相关业务及前述相关业务的管理、调查与研究分析及改善前述相关业务的特定目的范围内，您同意本公司得收集、处理、利用及跨境传输您注册、登录及使用本服务时以任何形式、方式所提供的下列个人信息（以下简称个人信息）：',
		line8: '(1) 基本信息：包含姓名、性别、生日、地址、电话及电子邮箱等。',
		line9: '(2) 身份信息：包含身份证号码、护照号码等相关身分证明，以及档案头像、身高、年龄等相关特征。',
		line10: '(3) 设备数据：包含硬件型号、设备MAC地址、操作系统类型、软件列表及国际移动设备识别码或运行中的进程信息等。',
		line11: '并且，本公司将在下列情形提供您的个人信息给本公司运营地域内、外的第三方或政府机关：',
		line12: '(1) 本公司因本条目的将您的个人信息共享或转让给本公司关系公司、关联公司及与本公司合作的第三方的，且该等公司、第三方得因本条目的使用您的个人信息；',
		line13: '(2) 本公司因您使用本服务涉及政府机关（包括但不限于税务机关、海关机关及司法机关等）的要求、或因您涉有犯罪嫌疑所必须配合提供的。',
		line14: '3、本公司为了提供您更好的服务及优化您的服务体验，在您使用本服务、本服务相关应用程序时，本公司将记录您的设备数据，包含网络协议地址（IP ADDRESS）及您的浏览纪录、COOKIES等，作为分析喜好与习惯、统计浏览人次及流量及按照前述分析、统计结果调整服务内容的用途。',
		line15: '4、本公司为了提供本服务，部分功能或服务需要使用第三方的SDK（包括但不限于金流、简讯验证和讯息推送等），您同意这些SDK可以收集或使用您的个人信息，以配合本公司提供前述服务。本公司将适时根据使用的SDK通过合适的版面和方式向您揭示SDK的名称和有关信息。',
		line16: '5、本公司将以合于法律法规的技术与程序保护您的个人信息安全，防止您的个人信息被窃取、窜改、毁损、灭失或泄漏，但您了解并同意，您如果透过网络使用本服务，由于网络可能存在各种恶意手段，即使本公司已采取前述技术、程序尽力保护您的个人信息，仍无法保证您透过网络提供个人信息的安全性，您务必主动采取安全措施（包括但不限于定期修改帐户密码等）并自行负担相关风险及责任。',
		line17: "6、您得就您的个人信息向本公司请求查询、阅览、或制给复制本、或补充、更正、或请求停止收集、处理或利用、或请求删除，本公司得就查询、阅览或制给复制本的请求酌收必要的处理费用。如果您需要删除您的账户，请通过下列步骤提交申请表单给我们，发送邮件至NBHD{'@'}orota.cn。",
		line18: '7、本公司非常重视未成年人的个人信息保护，将根据法律法规保护未成年人的个人信息。本公司如果发现在未经事前获得法定代理人、监护人的同意下收集了未成年人的个人信息，则会设法尽快删除相关信息。',
		line19: "8、您如果对本隐私政策有任何疑问、意见或建议，欢迎您透过本服务与我们联系，请发送邮件至NBHD{'@'}orota.cn，一般会在5个工作日内回复。您如果因为本隐私政策产生任何争议，您同意先与本公司进行友好协商，双方如果无法达成协商而涉讼，您同意以上海市闵行区人民法院作为管辖法院。",
		line20: '9、联系方式',
		line21: "电子邮箱: NBHD{'@'}orota.cn",
	},
	service: {
		line1: '本条款更新时间：2024年9月16日',
		line2: '本条款生效时间：2024年9月16日',
		line3: '《JJ20XNEIGHBORHOOD商城》服务（以下简称本服务）系由上海欧露塔科技有限公司（以下简称本公司）向您提供的用户服务。本服务条款是您与本公司之间就注册本服务账户和使用本服务各项服务相关事宜所订立的协议。',
		line4: '您在使用本服务之前，请务必经过充裕的时间仔细阅读并充分了解本服务条款的所有内容，一旦您以任何形式或方式开始使用本服务，即表示您已同意接受本服务条款的所有内容。',
		line5: '您的年龄如果未满18岁，请您在使用本服务之前，务必在您的法定代理人、监护人的陪同下经过充裕的时间仔细阅读并充分了解本服务条款的所有内容，一旦您以任何形式或方式开始使用本服务，即表示您已获得法定代理人、监护人同意接受本服务条款的所有内容。',
		line6: '1、前言',
		line7: '1.1 本公司得将本服务的部分或全部移转、让与给本公司的关系公司、关联公司或与本公司合作的第三方，由其进行运营、管理及履行。',
		line8: '1.2 本公司得变更或终止本服务条款的任一内容，并以公告通知或其他合理的方式通知您，但前述变更、终止不以您收到通知为必要，您如果在前述变更后继续使用本服务，即视为您已经同意接受本服务条款的变更、终止。',
		line9: '2、服务内容',
		line10: '2.1 本服务的具体内容由本公司根据实际情况提供，包括但不限于活动预约等，本公司并得随时对本服务进行更新、调整及维护，包括但不限于本服务的功能升级、强化、限制及加入新服务等，您了解并同意，本公司得不向您特别通知该等更新、调整及维护。',
		line11: '2.2 鉴于网络服务的特殊性（包括但不限于服务器的稳定性、恶意网络攻击及其他本公司无法控制的情形），本公司得随时中断或终止本服务的部分或全部服务；如有前述情形，本公司将以公告通知或其他合理的方式通知您。',
		line12: '2.3 你了解本公司透过网络提供本服务，您使用本服务所需要的相关设备（包含手机、计算机及平板等设备），以及您连接网络所需要的费用（包含电话费、上网费等），均由您自行承担。',
		line13: '2.4 商品和订单：',
		line14: '(1) 您在下订单前，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示将视为您的行为和意思表示，并且您应对收货人的行为与意思表示承担连带责任。',
		line15: '(2) 售卖页面仅为商品的展示，商品的实际提供者收到您的订单后，将根据商品是否缺货等情形接受您的订单，一旦商品的实际提供者接受了您的订单（提醒！下订单时因购买手续所自动发出的通知、验证讯息，不必然代表订单已经被接受），即表示您就所购商品与其成立购买合同的关系，由商品的实际提供者依照订单发货给您。',
		line16: '(3) 商品的实际提供者基于市场变化与各种合理商业努力仍无法避免的影响，其接受了订单之后假若发生缺货或其他无法出货的情形，商品的实际提供者有权与您协商取消订单、购买合同，若您已经付款的，则将为您办理退款。',
		line17: '3、账户规范',
		line18: '3.1 您使用本服务所注册的账户（以下简称账户），其名称、密码是您使用本服务的凭证，您不得任意向他人透漏账户名称及密码，并应适时更新密码及采取相关保护措施，以妥善维护该账户的安全性及保密性。您了解并同意，任何以该账户所从事的活动均由您承担所有责任，您如果怀疑、发现他人擅自使用该账户或有其他安全问题，请立即通知本公司。',
		line19: '3.2 您应使用真实身分的信息注册账户，不得捏造或假冒他人的身分信息、公司信息，包括但不限于冒用名称、头像或简介等，并请您按照最新的身分信息异动账户数据。您如果有任何捏造、冒用的情形或本公司有合理的理由怀疑账户数据有任何错误、不实或不合法，本公司得拒绝您使用本服务或限制您使用本服务的部分功能，您同意不作任何异议、主张。',
		line20: '3.3 您了解账户的所有权及相关权益均归本公司所有，您完成注册手续后仅享有该账户的使用权，且该使用权属于账户的初始注册人。您如果以赠与、借用、出租、转让、贩卖等任何形式或方式提供账户予他人使用，经本公司发现或本公司有任何合理理由认为账户的使用者非初始注册人，本公司得直接暂停、终止及注销账户，毋须事前通知您。',
		line21: '3.4 您如果遗忘账户的名称、密码，您可以透过本公司公布的手续申请找回账户的名称或密码。您了解前述手续仅需要识别申请者所提供的数据与本服务纪录的一致性，无法识别申请者是否是账户的真正使用权人，您不得以前述手续主张本公司已同意申请者为账户的使用权人。',
		line22: '4、行为规范',
		line23: '4.1 您应充分了解并同意，您须为自己账户下的一切行为负责，包括但不限于违法违规、违反本服务条款和该扰交易秩序等，以及由此产生的任何后果。',
		line24: '4.2 您使用本服务不得有下列行为：',
		line25: '(1) 冒充、利用他人名义；',
		line26: '(2) 通过不正当手段达到交易或参与活动的目的，例如使用机器人软件、模拟器等工具；',
		line27: '(3) 利用技术漏洞或服务条款漏洞进行下单、获取福利和补贴；',
		line28: '(4) 恶意退货、换货或藉此获得不正当利益；',
		line29: '(5) 非正常的多次下单、退单影响商品流转等干扰交易秩序；',
		line30: '(6) 违反善良风俗、法律法规或侵犯他人权利；',
		line31: '(7) 其他本公司认为影响他人权益或不适于本服务的行为。',
		line32: '4.3、您使用本服务有关程序的过程，不得有下列行为：',
		line33: '(1) 频繁、大量注册账户或以其他方式干扰程序；',
		line34: '(2) 反向工程、反向汇编、反向编译或以其他方式尝试发现程序原始码；',
		line35: '(3) 使用插件、外挂或任何第三方工具/服务复制、修改、增加、删除、挂接程序数据、数据或作成任何衍生作品；',
		line36: '(4) 其他未经本公司允许的行为。',
		line37: '4.4、您使用本服务时如果涉及第三方提供的服务，您除了遵守本服务条款之外，亦须遵守该服务的相关规范、规则，您如果因为该服务产生任何争议、损失等，由您自行与该第三方解决。',
		line38: '4.5、您如果有任何违反行为规范的情形或本公司有合理理由怀疑您的行为有任何违反行为规范的可能，本公司得拒绝您使用本服务或暂停、终止、注销您的账户，并向您追究相关责任。',
		line39: '5、未成年人条款',
		line40: '5.1 您如果未满18岁，您应该法定代理人、监护人的指导下使用本服务，并遵守下列规范：',
		line41: '(1) 要善于网上学习，不浏览不良信息；',
		line42: '(2) 要诚实友好交流，不侮辱欺诈他人；',
		line43: '(3) 要增强自护意识，不随意约会网友；',
		line44: '(4) 要维护网络安全，不破坏网络秩序；',
		line45: '(5) 要有益身心健康，不沉溺虚拟时空。',
		line46: '5.2 本公司非常注重未成年人的保护，未成年人如欲使用本服务进行任何消费行为，请您务必在法定代理人、监护人的陪同及明示同意下进行。',
		line47: '6、隐私政策',
		line48: '6.1 本公司注重您的个人信息及个人隐私，将运用与本服务相匹配的安全技术及其他安全措施保护您的个人信息。',
		line49: '6.2 请您务必经过充裕的时间仔细阅读并充分了解本公司《JJ20XNEIGHBORHOOD商城》隐私政策的所有内容，您如果不同意该等内容，可能导致本服务无法正常运作、或使相关功能受到限制；一旦您以任何形式或方式开始使用本服务，即表示您已同意接受该隐私政策的所有内容。',
		line50: '7、广告',
		line51: '7.1 您了解本服务内容可能包括本公司针对个人或企业的广告服务，您同意本公司得在本服务中展示本服务合作伙伴、赞助商、供货商的商业广告、推广或信息（包括商业或非商业信息）。',
		line52: '7.2 本公司将依照法律法规展示广告，您应自行判断该广告、推广及信息的真实性与可靠性，并应为自己的判断负责。除了法律法规另有明确规定外，您因该广告、推广及信息所进行的购买、交易行为，或因该等购买、交易行为遭受的损害或损失，均由您自行承担。',
		line53: '8、知识产权',
		line54: '8.1 本公司对本服务及相关应用程序提供的所有内容以及其开发、运营等过程中产生的所有程序代码和信息等享有全部权利，非经本公司事前书面同意，您不得以任何方式、形式（包括但不限于通过任何程序或设备复制、展示、传播、监视、镜像、上载、下载）使用前述内容、程序代码及信息。',
		line55: '8.2 您不得将本公司的任何商标、服务标记、商号、域名、网站名称或其他显着品牌特征等（以下统称为标识）以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。您如果因此造成本公司或任何第三方的损失，由您承担全部责任。',
		line56: '9、违约责任',
		line57: '9.1 针对您违反本服务条款的行为，本公司得独立判断并不经通知随时对相关内容进行删除、屏蔽，视情节对违规账户进行限制部分或全部功能、帐户封禁、注销等措施，并公告处理结果。对涉嫌违反法律法规的行为，本公司将保存有关记录，并依法配合有关主管部门调查。您应自行承担由此产生的一切法律责任。',
		line58: '9.2 您应了解并同意，因您违反本服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；本公司及其关系公司、关联公司及本公司合作公司如果因为您的行为遭受任何损失（包括但不限于经济、商誉等损失等），您亦应承担所有赔偿责任。',
		line59: '10、免责声明',
		line60: '10.1 您在使用本服务的过程中应遵守法律法规及政策规定，若因违反产生的行为后果由您自行承担。',
		line61: '10.2 鉴于外部链接指向的内容非由本公司实际控制，本公司无法保证设置的外部链接的准确性和完整性。',
		line62: '10.3 对于因不可抗力造成的本服务或相关应用程序中断或其它缺陷，本公司不承担任何责任。',
		line63: '10.4 本服务是本公司按照现有技术和条件所能达到的现状提供，本公司会尽最大努力确保服务的连贯性和安全性。您了解并同意，本公司不能随时预见和防范法律、技术以及其他风险，包括但不限于木马、病毒、黑客攻击、系统不稳定、第三方服务瑕疵、不可抗力及其他各种安全问题可能导致的服务中断、数据丢失以及其他的损失和风险。本公司得为服务运营的需要，采取合适的方式通知后修改、中断、中止或终止本服务，毋须承担任何赔偿责任。',
		line64: '11、其他',
		line65: '11.1 本服务条款以中华人民共和国法律为准据法。您如果因为本服务及本服务条款产生任何争议，您同意先与本公司进行友好协商，双方如果无法达成协商而涉讼，您同意以上海市闵行区人民法院作为管辖法院。',
		line66: '11.2 本服务条款的标题仅为阅读方便，本身并无实际涵义，不能作为本服务条款涵义解释的依据。',
		line67: '11.3 本服务条款如果因为任何原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效并且有约束力。',
		line68: '12、联系方式',
		line69: "电子邮箱: NBHD{'@'}orota.cn",
	}
};
